import {Injectable} from '@angular/core';
import {AddTagInterface} from '@common/components/add-tag/addTag.model';
import {TagTypes} from '@common/enum/tagTypes.enum';
import {GridRequest} from '@common/globals/gridRequest';
import {setResponseMessage} from '@common/statics/setResponseMessage';
import {BACKEND_URL} from '@common/ts/config';
import {BulkTagGridRequestDTO, BulkTagRequestDTO, MessageResponseContext, TagDTO} from '@common/ts/interfaces';
import {Observable} from 'rxjs';

import texts from './tagService';

@Injectable()
export class TagService extends AddTagInterface {

  // eslint-disable-next-line sonar/cyclomatic-complexity
  getServicePath(type: TagTypes, isSearch = false): string {
    switch (type) {
      case TagTypes.Image:
        return `${BACKEND_URL}/storage/image${isSearch ? '/getImageTagsWithPrefix' : ''}`;
      case TagTypes.Item:
        return `${BACKEND_URL}/inventory/item${isSearch ? '/getInventoryTagsWithPrefix' : ''}`;
      case TagTypes.Listing:
        return `${BACKEND_URL}/listing/multilistings${isSearch ? '/tags/prefix' : ''}`;
      case TagTypes.Order:
        return `${BACKEND_URL}/order${isSearch ? '/getOrderTagsWithPrefix' : ''}`;
      case TagTypes.Message:
        return `${BACKEND_URL}/notification/ebay/thread${isSearch ? '/getMessageThreadTagsWithPrefix' : ''}`;
    }
  }

  getTagResponseMessages(type: TagTypes): MessageResponseContext {
    return setResponseMessage({
      message: `${TagTypes[type]} ${texts.tagSaveSuccess}`,
      errorMessage: texts.tagSaveFailed,
    });
  }

  saveTags(id: string, tags: TagDTO[], type: TagTypes): Observable<boolean> {
    return this.http.post<boolean>(`${this.getServicePath(type)}/${type === TagTypes.Listing ?
      'tags/sync' : 'syncTags'}?${type === TagTypes.Listing ? 'masterListingId' : 'id'}=${id}`, tags,
    this.getTagResponseMessages(type));
  }

  saveTagsBulk(tagTexts: string[], targetIds: string[], type: TagTypes): Observable<boolean> {
    return this.http.post<boolean>(`${this.getServicePath(type)}/${type === TagTypes.Listing ? 'tags/' : 'addTags'}`,
      <BulkTagRequestDTO>{tagTexts, targetIds}, this.getTagResponseMessages(type));
  }

  saveTagsBulkWithFilter(excludedIds: string[], filterRequest: GridRequest, tagTexts: string[], type: TagTypes): Observable<boolean> {
    return this.http.post<boolean>(`${this.getServicePath(type)}/${type === TagTypes.Listing ? 'tags/byFilter' :
      'addTagsByFilter'}`, <BulkTagGridRequestDTO>{excludedIds, filterRequest, tagTexts}, this.getTagResponseMessages(type));
  }

  getTags(id: string, type: TagTypes): Observable<TagDTO[]> {
    return this.http.get<TagDTO[]>(`${this.getServicePath(type)}/${type === TagTypes.Listing ? 'tags/' :
      'getTags'}?${type === TagTypes.Listing ? 'masterListingId' : 'id'}=${id}`, setResponseMessage({
      errorMessage: texts.tagLoadFailed,
    }));
  }

  tagQuickSearch(tagText: string, type: TagTypes): Observable<TagDTO[]> {
    return this.http.get<TagDTO[]>(`${this.getServicePath(type, true)}?filter=${tagText}`);
  }

}
