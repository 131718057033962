import {HttpClient} from '@angular/common/http';
import {Directive} from '@angular/core';
import {FormControl} from '@angular/forms';
import {TagTypes} from '@common/enum/tagTypes.enum';
import {GridRequest} from '@common/globals/gridRequest';
import {CHANGE_FILTER} from '@common/statics/changeFilter';
import {TagDTO} from '@common/ts/interfaces';
import {Observable, of, Subject, switchMap, takeUntil} from 'rxjs';

@Directive()
export abstract class AddTagInterface {

  private tagCancellationToken = new Subject<void>();

  constructor(public http: HttpClient) {
  }

  abstract saveTags(id: string, tags: TagDTO[], type: TagTypes): Observable<boolean>;

  abstract saveTagsBulkWithFilter(excludedIds: string[], filterRequest: GridRequest, tagTexts: string[],
    type: TagTypes): Observable<boolean>;

  abstract saveTagsBulk(tagTexts: string[], targetIds: string[], type: TagTypes): Observable<boolean>;

  abstract getTags(id: string, type: TagTypes): Observable<TagDTO[]>;

  abstract tagQuickSearch(tagText: string, type: TagTypes): Observable<TagDTO[]>;

  tagSuggestion({valueChanges}: FormControl<string>, type: TagTypes): Observable<TagDTO[]> {
    return valueChanges.pipe(CHANGE_FILTER, switchMap((change) => {
      if (change?.length) {
        this.tagCancellationToken.next();
        return this.tagQuickSearch(change, type).pipe(takeUntil(this.tagCancellationToken));
      }
      return of([]);
    }));
  }

  removeTagDuplicates(response: TagDTO[], tags: TagDTO[]): TagDTO[] {
    return response.filter((tagData) => !tags.some(({tagText}) => tagText.toLowerCase() === tagData.tagText.trim().toLowerCase()));
  }

}
